import { useRef, useReducer, useState } from 'react'
import { useModalContext } from '@/context/ModalContext'
import { useCustomerContext } from '@/context/CustomerContext'
import { accountFormReducer, initialState } from '@/utils/account'
import classes from './AccountForm.module.scss'
import { useRouter } from 'next/router'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { validateEmail } from '@/utils/validation'
import { signIn } from 'next-auth/react'

const GoogleLogo = () => (
  <svg
    width="18"
    height="18"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
  >
    <path
      fill="#EA4335"
      d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
    />
    <path
      fill="#4285F4"
      d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
    />
    <path
      fill="#FBBC05"
      d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
    />
    <path
      fill="#34A853"
      d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
    />
  </svg>
)

const LoginAccountForm = ({ redirect, isCheckout }) => {
  const router = useRouter()
  const modalContext = useModalContext()
  const customerContext = useCustomerContext()
  const emailRef = useRef()
  const passwordRef = useRef()
  const [state, dispatch] = useReducer(accountFormReducer, initialState)
  const { showErrorMessage, errorMessage, isLoading } = state
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [googleLoading, setGoogleLoading] = useState(false)

  const handleGoogleLogin = async () => {
    try {
      setGoogleLoading(true)
      
      // Start Google authentication
      // We use explicit callbackUrl to ensure NextAuth will attempt to redirect there
      // after successful authentication
      await signIn('google', {
        redirect: true,
        callbackUrl: '/account/multipass-redirect',
      })
    } catch (error) {
      dispatch({ 
        type: 'error', 
        payload: 'Error signing in with Google. Please try again.' 
      })
      setGoogleLoading(false)
    }
  }

  const title = isCheckout ? null : (
    <>
      <h4>Log in To Your Sitka Seafood Member Portal</h4>
      <h5>
        Track orders and manage your
        <br /> subscription in your account.
      </h5>
    </>
  )

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible)
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    const email = emailRef.current.value
    const password = passwordRef.current.value

    if (!validateEmail(email)) {
      dispatch({
        type: 'error',
        payload: 'Please enter a valid email address.',
      })
      return
    }

    dispatch({ type: 'loading' })

    const response = await customerContext.login({
      email,
      password,
    })
    if (response.errors?.length) {
      let customErrorMessage = response.errors[0].message
      if (customErrorMessage === 'Unidentified customer') {
        customErrorMessage = 'Invalid email or password'
      }
      dispatch({ type: 'error', payload: customErrorMessage })
    } else {
      console.debug('customer login success', response)
      if (redirect) {
        router.push(redirect)
      } else {
        modalContext.setIsOpen(false)
      }
    }
  }

  return (
    <div
      className={`${classes['account-form']} ${isCheckout ? classes['account-form--checkout'] : ''}`}
    >
      {title}
      <form onSubmit={(e) => onSubmit(e)}>
        {showErrorMessage && (
          <p className={classes['account-form__error']}>{errorMessage}</p>
        )}
        <div
          className={isCheckout ? classes['account-form-input-wrapper'] : ''}
        >
          <div
            className={`input-group ${isCheckout ? 'input-group--ischeckout' : ''}`}
          >
            <input
              data-testid="login-email-input"
              type="email"
              className={`input ${isCheckout ? 'input--checkout' : ''}`}
              placeholder="email address"
              ref={emailRef}
            />
          </div>
          <div
            className={`input-group ${isCheckout ? 'input-group--ischeckout' : ''}`}
          >
            <input
              data-testid="login-password-input"
              type={isPasswordVisible ? 'text' : 'password'}
              className={`input ${isCheckout ? 'input--checkout' : ''}`}
              placeholder="password"
              ref={passwordRef}
            />
            <span
              onClick={togglePasswordVisibility}
              className={classes['password-toggle-icon']}
              data-testid="login-password-toggle"
            >
              {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
            </span>
          </div>

          <button
            className={`btn sitkablue ${isCheckout ? 'btn--ischeckout' : ''}`}
            disabled={isLoading}
          >
            Login
          </button>
        </div>
      </form>

      {!isCheckout && (
        <>
          <p>
            <button
              onClick={() => {
                modalContext.setIsOpen(true)
                modalContext.setModalType('forgot_password')
              }}
              className="btn-link-underline"
            >
              Forgot Password?
            </button>
          </p>
          <p>
            {`Don't have an account? `}
            <button
              onClick={() => {
                modalContext.setIsOpen(true)
                modalContext.setModalType('create')
              }}
              className="btn-link-underline"
            >
              Sign Up
            </button>
          </p>
        </>
      )}

      <div className={classes['separator']}>O</div>

      <button
        type="button"
        onClick={handleGoogleLogin}
        className={classes['google-login']}
        disabled={isLoading || googleLoading}
      >
        <GoogleLogo />
        {googleLoading ? 'Connecting...' : 'Continue with Google'}
      </button>
    </div>
  )
}

export default LoginAccountForm
